
import './App.css';
import ListPlayer from './ListPlayer';

function App() {
  return (
    <div className="App">
           
      <ListPlayer/>
    </div>
  );
}

export default App;
